export function base64ToString(base64) {
    const binString = atob(base64);
    const uint8Array =  Uint8Array.from(binString, (m) => m.codePointAt(0));
    return new TextDecoder().decode(uint8Array)
}

export function stringToBase64(str) {
    const bytes = new TextEncoder().encode(str)
    const binString = Array.from(bytes, (byte) =>
        String.fromCodePoint(byte),
    ).join("");
    return btoa(binString);
}
