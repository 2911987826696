import axios from "axios";
import {API} from "../../config/api";

export default class ApiWindowTime {
    static async get(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'windowTime/cpall',

                // For test localhost
                // 'http://127.0.0.1:8000/windowTime/cpall',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async updateDelivery(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'windowTime/updateDelivery/cpall',

                // For test localhost
                // 'http://127.0.0.1:8000/windowTime/updateDelivery/cpall',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async updatePickupCm(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'windowTime/updatePickup/cpall',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async import(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'windowTime/importWindowTime/cpall',
                data,
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data',
                    }, responseType: 'arraybuffer'
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async downloadTemplateImport() {
        try {
            return await axios.get(
                new API().getCusAPI()+'windowTime/downloadTemplate/cpall',
                {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data',
                    }, responseType: 'arraybuffer'
                }
            );
        } catch (e) {
            return e;
        }
    }
}