import {confirmAlert} from "react-confirm-alert";
import {Button, createTheme, Stack, ThemeProvider} from '@mui/material';
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import Lottie from 'react-lottie';
import * as truck from '../assets/lottie/truck.json'
import {API} from "../config/api";

export default function SplitTruckCustomDialog(title, truckList, master, data, approve, reject, isShowButton) {
    const options = {
        customUI: view,
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);

    function view({onClose}) {

        return (
            <ThemeProvider theme={theme}>
                <Card>
                    <div
                        className='center'
                        style={{
                            position: 'relative',
                            minWidth: '540px',
                            maxWidth: '90vw',
                            padding: '16px 0 16px 0'
                        }}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                right: '10px'
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </div>

                        <div>
                            <div style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                marginBottom: '16px',
                                paddingLeft: '16px',
                                paddingRight: '16px'
                            }}
                                 dangerouslySetInnerHTML={{
                                     __html: title || '-'
                                 }}/>
                            <Divider/>
                        </div>

                        <div style={{padding: '16px'}}>
                            ประเภทรถที่ลูกค้าขอ: <span style={{fontWeight: '400', color: '#f48c06'}}>{data.truckType ?? "-"}</span>
                        </div>

                        <DialogTruckList
                            truckList={truckList}
                            master={master}
                            trickingNo={data.trickingNo}
                            isShowButton={isShowButton}
                        />

                        <Divider/>

                        {
                            isShowButton ? <Stack direction="row" spacing={2} style={{
                                justifyContent: 'right',
                                paddingTop: '16px',
                                paddingRight: '16px',
                                paddingLeft: '16px'
                            }}>
                                <Button
                                    id="dialog-btn-close"
                                    variant={"contained"}
                                    size={'small'}
                                    style={{
                                        width: '120px',
                                        fontWeight: 'lighter',
                                        marginRight: '15px',
                                        backgroundColor: '#dc2f02'
                                    }}
                                    onClick={() => {
                                        onClose()
                                        reject()
                                    }}
                                > {`ปฎิเสธ`} </Button>
                                <Button
                                    id="dialog-btn-close"
                                    variant={"contained"}
                                    size={'small'}
                                    style={{width: '120px', fontWeight: 'lighter', backgroundColor: '#1976d2'}}
                                    onClick={() => {
                                        onClose()
                                        approve()
                                    }}
                                > {`ยืนยัน`} </Button>
                            </Stack> : null
                        }

                    </div>
                </Card>
            </ThemeProvider>
        )
    }
}

function DialogTruckList(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: truck,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {

    }, [])

    return (
        <div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'absolute',
                top: '80px',
                right: '20px'
            }}>
                <div/>
                <span style={{
                    fontSize: '16px',
                    fontWeight: '400'
                }}>{props.truckList?.length ?? 0}</span>
            </div>

            <div style={{ maxHeight: '500px', overflowX: 'hidden'}}>
                <Stack direction="column" spacing={2} style={{ padding: '16px', minHeight: '200px', paddingTop: '0px' }}>
                    {
                        props.truckList?.map((value, index) =>
                            <div>
                                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    หมายเลขติดตาม: {value.buTrackingNo ?? "-"}
                                    <span style={{fontWeight: '400', color: '#f48c06', marginLeft: '10px'}}>
                                        {value.truckTypeName ?? '-'}
                                    </span>
                                </span>

                                <div style={{ marginBottom: '5px' }}>หมายเลข TMS: {
                                    value.tmsOrderNo ?
                                        <a href={new API().getTMSAPI() + 'activity-tracking/detail/job/' + getTmsNo(value.tmsOrderNo)}
                                           target="_blank"
                                           rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                            {value.tmsOrderNo}
                                        </a>
                                        : "-"
                                } </div>

                                <Stack direction="row" spacing={1} style={{
                                    paddingTop: '0px',
                                    width: '100%',
                                    backgroundColor: '#f3f3f3',
                                    borderRadius: '3px'
                                }}>
                                    <div style={{display: 'flex', alignItems: 'start', width: '100%'}}>
                                        <div style={{width: '50%', padding: '10px'}}>
                                            <div>ทะเบียน</div>
                                            <div>{value.licensePlateNo ?? '-'}</div>
                                        </div>

                                        <div style={{width: '50%', padding: '10px'}}>
                                            <div>คนขับรถ</div>
                                            {
                                                value.drivers?.map((driver, index) =>
                                                    <div>{index + 1}. {driver.driverName ?? '-'}</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        )
                    }

                    {
                        (props.truckList?.length ?? 0) <= 0 ?
                            <div style={{
                                opacity: 0.5,
                                marginBottom: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                flexDirection: 'column'
                            }}>
                                <Lottie
                                    options={defaultOptions}
                                    height={200}
                                    width={250}
                                    isClickToPauseDisabled={true}
                                />
                            </div> : null
                    }
                </Stack>
            </div>
        </div>
    )

    function getTmsNo(value) {
        const stringTmsNo = value;
        const remove5StringFirst = stringTmsNo.substring(5);
        const integerTmsNo = parseInt(remove5StringFirst);

        return integerTmsNo
    }
}

const styleOverride = {
    styleOverrides: {
        root: {
            fontFamily: 'Kanit',
            fontSize: 16,
        },
    }
}

const theme = createTheme({
    components: {
        MuiOutlinedInput: styleOverride,
        MuiInputLabel: styleOverride,
        MuiMenuItem: styleOverride,
        MuiButton: styleOverride,
        MuiAvatar: styleOverride,
    },
    palette: {
        primary: {
            main: '#302E2E',
        },
        secondary: {
            main: '#747474',
        },
        info: {
            main: '#397EF5',
        },
        error: {
            main: '#B64648',
        },
        warning: {
            main: '#B64648',
        },
    },
});