import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useForm } from "react-hook-form";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import {API} from "../../config/api";

function ReportsDailyAnl() {
  const pathApi = new API().getCusAPI();
  const [loading, setLoading] = useState(false);

  // ------------ เพิ่มข้อมูล / update ---------------

  const {
    register: register,
    handleSubmit: handleSubmitInsert,
    reset: resetInsert,
  } = useForm();

  const InsertFileUpload = (data) => {

    console.log(data);
    setLoading(true);
    axios
      .post(pathApi + "reportsDailyAnl", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        Swal.fire({
          title: "ดำเนินการสำเร็จ",
          // text: result.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {});
        resetInsert();
        setLoading(false);

        console.log(response.data.data);
      })
      .catch(function (error) {

        setLoading(false);
        Swal.fire({
          title: "เกิดข้อผิดพลาดในการ Upload ไฟล์",
          // text: result.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {});

        console.error(error);
      });
  };

  // ------------ End เพิ่มข้อมูล / update ----------

  const serviceType = ["Cross Dock_Chill", "Super Sub"];
  return (
    <>

      {loading ? (

          <LinearProgress />
      ) : ( <></> )}

        <form onSubmit={handleSubmitInsert(InsertFileUpload)} encType="multipart/form-data">
        <Grid container spacing={2} sx={{ padding: "15px" }}>
          <Grid xs={12}>
            <Typography fontSize={"25px"}>นำเข้าข้อมูล Daily Makro</Typography>
          </Grid>

          <Grid xs={3}>
            <Typography>อัพโหลด</Typography>
            <TextField
              fullWidth
              type={"file"}
              size={"small"}
              variant="outlined"
              {...register("files")}
            />
            <Stack spacing={2} direction="row" sx={{ paddingTop: "15px" }}>
              <Button
                variant="contained"
                type="submit"
              >
                Upload
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>



    </>
  );
}

export default ReportsDailyAnl;
