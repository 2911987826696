import React, {useEffect, useState} from 'react';
import {PowerBIEmbed} from "powerbi-client-react";
import {models, Report, Embed, service, Page} from 'powerbi-client';
import {useParams, useSearchParams} from "react-router-dom";
import ApiReport from "../../services/api/ApiReport";
import Loading from "../Loading";

export default function PowerBI(props) {
    const vendorCode = JSON.parse(localStorage.getItem("profile")).vendorCode
    const buCode = JSON.parse(localStorage.getItem("profile")).bu_code
    const reportClass = 'report-container';

    const {id} = useParams();
    const [searchParams] = useSearchParams();
    const isWebView = searchParams.get('webView');
    const reportId = searchParams.get('reportId');
    const groupId = searchParams.get('groupId');
    const [reportConfig, setReportConfig] = useState(undefined)
    const [filters, setFilters] = useState(undefined);

    const embedUrl = "https://app.powerbi.com/reportEmbed?reportId=" + reportId + "&groupId=" + groupId

    const status = {
        Enabled: 0,
        Disabled: 1,
        Hidden: 2
    }

    const setActivePageByIndex = (report, index) => {
        if (report) {
            report.getPages().then((pages) => {
                if (pages && pages.length > index) {
                    pages[index].setActive().then(() => {
                        console.log(`===== Active page set to index ${index} (${pages[index].displayName})`);
                    }).catch((error) => {
                        console.error("===== Error setting active page: ", error);
                    });
                } else {
                    console.error(`===== No page exists at index ${index}.`);
                }
            }).catch((error) => {
                console.error("===== Error getting pages: ", error);
            });
        }
    };

    useEffect(() => {
        ApiReport.getReport(id, reportId, groupId).then((res) => {
            if ((res.data.settings.commands?.length ?? 0) > 0) {
                if (res.data.settings.commands[0].spotlight.displayOption) {
                    res.data.settings.commands[0].spotlight.displayOption = status[res.data.settings.commands[0].spotlight.displayOption]
                }

                if (res.data.settings.commands[0].exportData.displayOption) {
                    res.data.settings.commands[0].exportData.displayOption = status[res.data.settings.commands[0].exportData.displayOption]
                }
            }

            setReportConfig(res.data)

            if (res.data.filter) {
                let newFilterRes = {...res.data.filter}
                // newFilterRes.values = vendorCode ? [vendorCode] : []

                // New logic
                newFilterRes.values = getValues(newFilterRes.values, buCode, vendorCode)
                setFilters(newFilterRes)

                console.log("===== Debug filter: ", newFilterRes)
            }

        }).catch(err => console.log("===== Debug error: ", err))
    }, [])

    return (reportConfig ?
            <div className={'custom-body'}>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        embedUrl: embedUrl,
                        tokenType: models.TokenType.Embed,
                        accessToken: reportConfig.token,
                        filters: filters ? [filters] : [],
                        settings: reportConfig.settings
                    }}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                async function () {
                                    setActivePageByIndex(window.report, reportConfig.activePage ?? 0)
                                }
                            ],
                            ['rendered', async function () {}],
                            ['error', function (event) {}],
                            ['visualClicked', () => {}],
                            ['pageChanged', (event) => {}],
                        ])
                    }
                    cssClassName={reportClass}
                    getEmbeddedComponent={(embedObject) => {
                        window.report = embedObject;
                    }}
                />;
            </div> : <Loading isWebView={"true"}/>
    )

    function getValues(values, buCode, vendorCode) {
        if (values) {
            if (values === "#VENDOR_SAP_CODE") {
                return vendorCode ? [vendorCode] : []
            } else if (values === "#BU") {
                return buCode ? buCode : []
            }
        }

        return []
    }
}