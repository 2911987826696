import React, {Component, useState,useContext, useEffect,useRef} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";
import Home from './Home.js';
import ApiImportFileXlsx from '../services/api/ApiImportFileXlsx';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import RefreshIcon from '@mui/icons-material/Refresh';
import {AuthContext} from '../App.js';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ApiTMSOrderLog from "../services/api/ApiTMSOrderLog.js";
import ApiOrderManagement from "../services/api/ApiOrderManagement";
import ApiShipmentImportFile from "../services/api/ApiShipmentImportFile";
import FileDownload from "js-file-download";

export default function LogCreateOrderTMS() {
    const {open, setOpen, userNPass,setUserNPass, profiles, setProfiles, isLoading, setIsLoading, isProfiles, setIsProfiles, isProgress, setIsProgress} = useContext(AuthContext);
    const [drawerOpenBtn, setDrawerOpenBtn] = useState(false);
    const [drawer, setDrawer] = useState('none');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const columns = [
        // { field: 'id', headerName: 'ลำดับที่' , width: 80,editable:false, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,headerClassName: 'super-app-theme--header', hideable: true},
        { field: 'no', headerName: 'ลำดับ' , flex: 1,editable:false, renderCell: (params) => params.no},
        {
            field: 'date',
            headerClassName: 'super-app-theme--header',
            headerName: 'Date',
            minWidth: 120,
            flex: 2,
            sortable: false ,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'trickingNo',
            headerClassName: 'super-app-theme--header',
            headerName: 'Tracking No',
            minWidth: 160,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'tmsId',
            headerClassName: 'super-app-theme--header',
            headerName: 'TMS ID',
            minWidth: 80,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerClassName: 'super-app-theme--header',
            headerName: 'สถานะ',
            minWidth: 180,
            flex: 1,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let name = '';
                if (params.row.status === 'Waiting'){
                    name = 'รอการจัดสรร'
                } else if (params.row.status === 'Allocated'){
                    name = 'รอ Operation ยืนยัน'
                } else if (params.row.status === 'Approved'){
                    name = 'รอ Vendor ยืนยัน'
                } else if (params.row.status === 'Done'){
                    name = 'ส่งไป TMS สำเร็จ'
                } else if (params.row.status === 'Fail'){
                    name = 'ส่งไป TMS \nไม่สำเร็จ'
                }

                return (
                    <div>
                        {name}
                    </div>
                )}
        },
        {
            field: 'errorMessage',
            headerClassName: 'super-app-theme--header',
            headerName: 'TMS Error message',
            minWidth: 380,
            flex: 8,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (!params.row.errorMessage) {
                    return <></>;
                }

                const regex = /file name: (\d+-\d+_ShippingOrderDirectSchedule\.xlsx)/;
                const match = params.row.errorMessage.match(regex);

                if (!match || !match[1]) {
                    return <Typography>Error: Invalid file name format.</Typography>;
                }

                const fileName = match[1];

                return (
                    <Typography onClick={async () => {
                        setIsLoading(true);
                        setIsProgress(true);

                        try {
                            let res = await ApiTMSOrderLog.downloadLog({ fileName: fileName });
                            if (res.status !== 200) {
                                setIsLoading(false);
                                setIsProgress(false);
                                Swal.fire({
                                    title: 'ดาวน์โหลดไม่สำเร็จ',
                                    text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                    icon: 'error',
                                    showConfirmButton: false,
                                });
                            } else {
                                FileDownload(res.data, fileName);
                            }
                        } catch (error) {
                            setIsLoading(false);
                            setIsProgress(false);
                            console.error('Error downloading file:', error);
                        }

                        setIsLoading(false);
                        setIsProgress(false);
                    }}>
                        {params.row.errorMessage}
                    </Typography>
                );
            },
        },
        {
            field: 'repush',
            headerClassName: 'super-app-theme--header',
            headerName: 'Repush',
            minWidth: 77,
            flex: 1,
            sortable: false,
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    params.row.httpCode === '500' &&(
                        <IconButton disabled={isProgress} sx={{p:0, m: 0}}><RefreshIcon/></IconButton>
                    )
                )}
        },
        {
            field: 'httpCode',
            headerClassName: 'super-app-theme--header',
            headerName: 'HTTP code',
            minWidth: 60,
            flex: 1,
            sortable: false,
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
        },
    ];

    const [search, setSearch] = useState({
        date:null,
        trackingNo:'',
        tmsId:'',
        status:["Done","Fail"],
        httpCode:'ทั้งหมด',
    });


    useEffect( () =>{
        async function inti() {
            console.log('useEffect')
            await fetchData().catch((e)=>{
                console.log(e)
                setIsLoading(false)
                setIsProgress(false)
            })
        }
        inti().catch(console.error)
    }, []);

    const fetchData = async () => {
        setIsLoading(true)
        setIsProgress(true)
        let res = await ApiTMSOrderLog.search({
            date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            trackingNo: search.trackingNo,
            tmsId: search.tmsId,
            status: search.status.length > 0 ? search.status : ["Done","Fail"],
            httpCode: search.httpCode,
            page: page,
            limit: rowsPerPage,
        });

        if (res.status === 200){
            console.log(res)
            setRows(res.data)
        }
        setIsLoading(false)
        setIsProgress(false)
    };

    const DrawerBtn = () => {
        setDrawerOpenBtn(!drawerOpenBtn)
        if (drawerOpenBtn === false) {
            setDrawer('block')
        }
        if (drawerOpenBtn === true) {
            setDrawer('none')
        }
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
        // const dataSearch = {
        //     requestType: search.requestType,
        //     site: search.site,
        //     status: search.status,
        //     licensePlateNo: search.licensePlateNo,
        //     vendorName: role[0] === 'ROLE_VENDOR' ? identity.vendorName : search.vendorName,
        //     createDate: search.createDate ? new Date(search.createDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
        //     page: page,
        //     limit: rowsPerPage,
        // }
        // //console.log(newPage);
        // //console.log(rowsPerPage);
        // await fetchData(dataSearch).catch(console.error)
    };

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        // const dataSearch = {
        //     requestType: search.requestType,
        //     site: search.site,
        //     status: search.status,
        //     licensePlateNo: search.licensePlateNo,
        //     vendorName: role[0] === 'ROLE_VENDOR' ? identity.vendorName : search.vendorName,
        //     createDate: search.createDate ? new Date(search.createDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
        //     page: page,
        //     limit: rowsPerPage,
        // }
        // await fetchData(dataSearch).catch(console.error)
    };

    return(
        <>
            {
                !isProfiles
                    ? <Home />
                    :
                    <Box sx={{display: {xs: open ? 'none' : 'block', sm: 'block'}, overflow:'auto'}}>
                        <Grid container>
                            <Grid xs={11} item>
                                <Typography variant="h5" sx={{pl: '0px', pb: '15px', fontSize:'22px'}}>
                                    ผลการสร้าง TMS's order Page
                                </Typography>
                            </Grid>
                            <Grid container item xs={1} sx={{display: {xs: 'block', sm: 'none'},}} direction="row" alignItems="end">
                                <IconButton aria-label="delete" onClick={DrawerBtn}>
                                    {drawerOpenBtn ? <ExpandMore/> : <ExpandLess/>}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{mb: '10px'}}/>
                        <Grid container spacing={2} sx={{pt: 1, pl: {xs: 0, md: 1}, display: {xs: drawer, sm: 'block'},}}>
                            <Grid container spacing={2} item>
                                {/*search*/}
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        Date:
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DemoContainer components={['DatePicker','DatePicker','DatePicker']}>
                                            <DatePicker
                                                value={search.date}
                                                label={'DD/MM/YY'}
                                                views={['day', 'month', 'year']}
                                                onChange={(newValue) => {
                                                    setSearch(search=>({...search,...{date: newValue}}))
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth sx={{py: '3px', '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} />}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        Tracking No:
                                    </Typography>
                                    <TextField
                                        value={search.trackingNo}
                                        fullWidth
                                        sx={{pt: '8px'}}
                                        onChange={(e)=>{
                                            setSearch(search=>({...search,...{trackingNo: e.target.value}}))
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        TMS ID:
                                    </Typography>
                                    <TextField
                                        value={search.tmsId}
                                        fullWidth
                                        sx={{pt: '8px'}}
                                        onChange={(e)=>{
                                            setSearch(search=>({...search,...{tmsId: e.target.value}}))
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        Status:
                                    </Typography>
                                    <Select
                                        fullWidth
                                        sx={{height:'56px',mt:'8px'}}
                                        value={search.status}
                                        multiple
                                        renderValue={(selected) => selected.join(', ')}
                                        onChange={(event)=>{
                                            const {target: {value}} = event;
                                            setSearch(search =>({
                                                ...search,
                                                ...{status: typeof value === 'string' ? value.split(',') : value}
                                            }))
                                        }}
                                    >
                                        {/*<MenuItem value={'ทั้งหมด'}>ทั้งหมด</MenuItem>*/}
                                        <MenuItem value={'Done'}>Done</MenuItem>
                                        <MenuItem value={'Fail'}>Fail</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        HTTP code:
                                    </Typography>
                                    <Select
                                        fullWidth
                                        sx={{height:'56px',mt:'8px'}}
                                        value={search.httpCode}
                                        onChange={(e)=>{
                                            setSearch(search =>({
                                                ...search,
                                                ...{httpCode: e.target.value}
                                            }))
                                        }}
                                    >
                                        <MenuItem value={'ทั้งหมด'}>ทั้งหมด</MenuItem>
                                        <MenuItem value={'200'}>200</MenuItem>
                                        <MenuItem value={'400'}>400</MenuItem>
                                        <MenuItem value={'401'}>401</MenuItem>
                                        <MenuItem value={'403'}>403</MenuItem>
                                        <MenuItem value={'404'}>404</MenuItem>
                                        <MenuItem value={'500'}>500</MenuItem>
                                        <MenuItem value={'501'}>501</MenuItem>
                                        <MenuItem value={'502'}>502</MenuItem>
                                        <MenuItem value={'503'}>503</MenuItem>
                                        <MenuItem value={'504'}>504</MenuItem>
                                        <MenuItem value={'505'}>505</MenuItem>

                                    </Select>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={4} item sx={{py: 1}} container
                                      direction="row"
                                      justifyContent="flex-end"
                                      alignItems="end"
                                >
                                    <Button
                                        disabled={isProgress}
                                        variant="contained"
                                        disableElevation
                                        onClick={async ()=>{
                                            await fetchData().catch((e)=>{
                                                console.log(e)
                                                setIsLoading(false)
                                                setIsProgress(false)
                                            })
                                        }}
                                        sx={{
                                            // fontWeight: 'bold',
                                            backgroundColor: '#6F9EE8',
                                            color: '#ffffff',
                                            // width:'100px',
                                            ':hover': {
                                                backgroundColor: '#4380E1',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        Search
                                    </Button>

                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} item>
                                    <Divider  sx={{mb: '10px'}}/>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} item sx={{py: 1}} container direction="row" justifyContent="flex-start" alignItems="end">
                                    <Button
                                        disabled={isProgress}
                                        variant="contained"
                                        disableElevation
                                        sx={{
                                            // fontWeight: 'bold',
                                            backgroundColor: '#6F9EE8',
                                            color: '#ffffff',
                                            // width:'100px',
                                            ':hover': {
                                                backgroundColor: '#4380E1',
                                                color: 'white',
                                            },
                                        }}
                                        onClick={()=>{
                                            Swal.fire({
                                                title: 'Repush All',
                                                text: "มีรายการที่ fail ทั้งหมด xx รายการ คุณต้องการ create order อีกครั้งหรือไม่ ?",
                                                icon: 'question',
                                                showCancelButton: true,
                                                cancelButtonText: 'Cancel',
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Confirm'
                                            }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                    await setIsLoading(true)


                                                    await setIsLoading(false)
                                                } else if (result.dismiss === Swal.DismissReason.cancel){

                                                }
                                            })
                                        }}
                                    >
                                        Repush All
                                    </Button>
                                </Grid>
                            </Grid>
                            {/*<Divider sx={{mb: '10px'}}/>*/}
                        </Grid>
                        <Grid container sx={{pb:'50px'}} >
                            <Grid xs={12} item>




                                <DataGrid
                                    sx={{
                                        mt:'15px',
                                        flex: 21 ,
                                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                            outline: 'none',
                                        },
                                        '&.MuiDataGrid-root .MuiDataGrid-cell' : {
                                            whiteSpace: "normal !important",
                                            wordWrap: "break-word !important",
                                            textAlign: 'center',
                                        },
                                    }}
                                    autoHeight
                                    // rowHeight={40}
                                    loading={isProgress}
                                    rows={rows}
                                    columns={columns}
                                    slots={{
                                        toolbar: GridToolbar,
                                        loadingOverlay: LinearProgress,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            setRows ,
                                            csvOptions: { disableToolbarButton: true },
                                            printOptions: { disableToolbarButton: true },
                                        },
                                    }}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 10, page: 0 } },
                                    }}
                                    rowSelection={false}
                                    disableSelectionOnClick
                                    disableDensitySelector
                                    disableColumnFilter
                                    disableColumnMenu
                                    headerAlign="center"
                                    pageSize={rowsPerPage}
                                    // rowsPerPageOptions={[10, 50, 100]}
                                    pageSizeOptions={[100, 50, 10]}
                                    onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                                />
                            </Grid>
                        </Grid>

                        {/*<Box sx={{ width: '100%', typography: 'body1' }}>*/}
                        {/*</Box>*/}
                    </Box>
            }
        </>
    )
}