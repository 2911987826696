export function dateFormatYYYYMMDD(date: Date) {
    const year = date.getFullYear(); // Get current year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get current month and pad with zero
    const day = String(date.getDate()).padStart(2, '0'); // Get current day and pad with zero

    return `${year}${month}${day}`;
}

export function convertToISOWithOffset(date, offsetHours) {
    // Create a new date object with the specified date
    const dateCopy = new Date(date);

    // Adjust the date by adding the offset in hours
    dateCopy.setHours(dateCopy.getHours() + offsetHours);

    // Return the ISO string in UTC for the adjusted time
    return dateCopy.toISOString();
}