import {confirmAlert} from "react-confirm-alert";
import {Button, createTheme, Stack, SvgIcon, ThemeProvider} from '@mui/material';
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import Lottie from 'react-lottie';
import * as upload from '../assets/lottie/upload.json';
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Link from '@mui/material/Link';

export default function MassAllocateDialog(title, roles, customerName, master, onChange) {
    const options = {
        customUI: view,
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);

    function view({onClose}) {

        return (
            <ThemeProvider theme={theme}>
                <Card>
                    <div
                        className='center'
                        style={{
                            position: 'relative',
                            minWidth: '450px',
                            maxWidth: '90vw',
                            padding: '16px 0 16px 0'
                        }}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                right: '10px'
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </div>

                        <div>
                            <div style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                marginBottom: '16px',
                                paddingLeft: '16px',
                                paddingRight: '16px'
                            }}
                                 dangerouslySetInnerHTML={{
                                     __html: title || '-'
                                 }}/>
                            <Divider/>
                        </div>

                        <MassAllocateView
                            roles={roles}
                            customerName={customerName}
                            master={master}
                            onClose={onClose}
                            onClick={(file, customer, isLoadFile) => {
                                onChange(file, customer, isLoadFile)
                                onClose()
                            }}
                        />
                    </div>
                </Card>
            </ThemeProvider>
        )
    }
}

function MassAllocateView(props) {
    const [customer, setCustomer] = useState(null)
    const [file, setFile] = useState(null)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: upload,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        setCustomer(props.customerName)
    }, [])

    return (
        <div>
            <div style={{padding: '16px'}}>
                <Link
                    style={{ textDecorationColor: '#004efd', color: '#004efd' }}
                    component="button"
                    onClick={() => {
                        if (props.onClick) {
                            props.onClick(null, customer, true)
                        }
                    }}
                >
                    ดาวน์โหลดตัวอย่างไฟล์
                </Link>
            </div>

            <div style={{padding: '16px'}}>
                <Typography variant="subtitle1">
                    ชื่อลูกค้า:
                </Typography>
                <Select
                    size="small"
                    disabled={props.roles[0] === 'ROLE_BU'}
                    sx={{mt: '5px'}}
                    fullWidth
                    value={customer}
                    onChange={(e) => {
                        setCustomer(e.target.value)
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: '300px'
                            }
                        }
                    }}
                >
                    {
                        props.master?.map(m => <MenuItem value={m}>{m}</MenuItem>)
                    }
                </Select>
            </div>

            <input
                type="file"
                hidden
                onChange={(event) => {
                    setFile(event.target.files[0])
                }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                id="contained-dialog-mass-file"
            />
            <label htmlFor="contained-dialog-mass-file">
                <div
                    style={{
                        opacity: file ? '1' : '0.5',
                        textAlign: 'center',
                        width: '100%',
                        cursor: 'pointer',
                        marginBottom: '60px',
                        marginTop: '10px'
                    }}
                >
                    <Stack direction="column" spacing={2} style={{minHeight: '220'}}>
                        {
                            file ?
                                <div style={{ width: '100%', textAlign: 'center', marginBottom: '5px' }}>
                                    <SvgIcon
                                        // color={'success'}
                                        style={{ fontSize: '100px', opacity: 0.4 }}
                                    >
                                        <InsertDriveFileIcon />
                                    </SvgIcon>
                                </div> :
                                <div style={{
                                    opacity: 0.5,
                                    marginBottom: '-20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <Lottie
                                        options={defaultOptions}
                                        height={150}
                                        width={200}
                                        isClickToPauseDisabled={true}
                                    />
                                </div>
                        }
                    </Stack>

                    <div style={{fontSize: file ? '15px' : '20px', fontWeight: 'bold', paddingLeft: '16px', paddingRight: '16px'}}>
                        {file ? file.name ?? '-' : 'เลือกไฟล์'}
                    </div>
                </div>
            </label>

            <Divider/>

            <Stack direction="row" spacing={2} style={{
                justifyContent: 'right',
                paddingTop: '16px',
                paddingRight: '16px',
                paddingLeft: '16px'
            }}>
                <Button
                    id="dialog-btn-close"
                    variant={"contained"}
                    size={'small'}
                    style={{width: '100px', fontWeight: 'lighter'}}
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}
                > ยกเลิก </Button>

                <Button
                    id="dialog-btn-close"
                    variant={"contained"}
                    color="secondary"
                    size={'small'}
                    disabled={!file || !customer}
                    style={{
                        width: '100px',
                        fontWeight: 'lighter',
                        backgroundColor: !file || !customer ? 'lightgray' : '#69C361',
                        color: 'white',
                    }}
                    onClick={() => {
                        if (props.onClick) {
                            props.onClick(file, customer, false)
                        }
                    }}
                > บันทึก </Button>
            </Stack>
        </div>
    )
}

const styleOverride = {
    styleOverrides: {
        root: {
            fontFamily: 'Kanit',
            fontSize: 16,
        },
    }
}

const theme = createTheme({
    components: {
        MuiOutlinedInput: styleOverride,
        MuiInputLabel: styleOverride,
        MuiMenuItem: styleOverride,
        MuiButton: styleOverride,
        MuiAvatar: styleOverride,
    },
    palette: {
        primary: {
            main: '#302E2E',
        },
        secondary: {
            main: '#747474',
        },
        info: {
            main: '#397EF5',
        },
        error: {
            main: '#B64648',
        },
        warning: {
            main: '#B64648',
        },
    },
});