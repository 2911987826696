import React, {Component, useState,useContext, useEffect,useRef} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import {Divider, Grid, IconButton, Stack} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import ApiLogin from '../../services/api/ApiLogin';
import {AuthContext} from '../../App.js';

import logo from '../../assets/images/logo1.jpg';
import backgroundImage  from '../../assets/images/sv-img-1.png';
import {useNavigate} from "react-router-dom";

const styles = {
    paperContainer: {
        backgroundImage: `url(${backgroundImage})`
    }
};

export default function LoginPage() {
    const navigate = useNavigate();
    const ref_email = useRef(null)
    const {userNPass,setUserNPass, auth, setAuth, isLoading, setIsLoading, isProfiles, setIsProfiles} = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginFail,setLoginFail] = useState(false);

    const submitLogin = async () => {
        setIsLoading(true)
        let dataLogin = {
            username: username,
            password: password
        }
        let resLogin = await ApiLogin.login(dataLogin)
        await afterApi(resLogin);
        setIsLoading(false)
    }

    const afterApi = async (res) => {
        if (res.status === 200){
            // console.log('200')
            // console.log(res.data)
            await localStorage.setItem('userNPass', JSON.stringify({username: username, password: password}));
            setUserNPass({username: username, password: password})
            localStorage.setItem('auth', JSON.stringify(res.data.token));
            setAuth(res.data.token);
            navigate("/");
        } else {
            console.log('API login fail')
            setUsername('')
            setPassword('')
            setLoginFail(true)
            setIsProfiles(false)
            await localStorage.setItem('userNPass', JSON.stringify(null));
            setUserNPass(null);
            localStorage.setItem('auth', JSON.stringify(null));
            setAuth(null);
            ref_email.current.focus();
        }
    }

    return(
        <>
           <Grid container component="main" sx={{ height: '100vh' }}>
               <Grid item xs={12} sm={12} md={12} lg={12}  elevation={6} square="true">
                   <Box
                       sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'center',
                           width:'100%',
                           // backgroundColor: '#e7d166'
                       }}
                   >
                       <Grid sx={{pt:'50px'}}>
                           <img src={logo} width={'250px'} className="image"  />
                       </Grid>
                       <Typography component="h2" variant="h5" sx={{ pt:'40px', color:'#4677A0',fontSize:'22px' }} gutterBottom>
                           เข้าใช้งานระบบ
                       </Typography>
                       <Grid sx={{ mt: 2, minWidth:{xs:'280px', sm:'350px', md:'420px'} }} direction="column">
                           <Typography component="h3" variant="subtitle1" sx={{ pt:'10px', color:'#4677A0', fontWeight: 'regular' }} gutterBottom>
                               อีเมลผู้ใช้
                           </Typography>
                           <TextField
                               inputRef={ref_email}
                               value={username}
                               sx={{p:0,m:0,borderRadius:'10px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, maxWidth: '420px'}}
                               margin="normal"
                               required
                               id="email"
                               name="บัญชีผู้ใช้"
                               autoComplete="email"
                               type="email"
                               autoFocus
                               fullWidth
                               onChange={(e)=>{
                                   setUsername(e.target.value)
                                   setLoginFail(false)
                               }}
                               error={loginFail}
                               helperText={loginFail && <p style={{color:'#d71818',padding:'0px',margin:'0px'}}>โปรดเข้าสู่ระบบอีกครั้ง</p>}
                           />
                           <Typography component="h3" variant="subtitle1" sx={{ pt:'30px', color:'#4677A0', fontWeight: 'regular' }} gutterBottom>
                               รหัสผ่าน
                           </Typography>
                           <TextField
                               sx={{p:0,m:0,borderRadius:'10px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, maxWidth: '420px'}}
                               margin="normal"
                               required
                               fullWidth
                               name="รหัสผ่าน"
                               value={password}
                               type={showPassword ? 'text' : 'password'}
                               id="password"
                               autoComplete="current-password"
                               onChange={(e)=>{
                                   setPassword(e.target.value)
                               }}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton
                                               aria-label="toggle password visibility"
                                               onClick={()=>{
                                                   setShowPassword(!showPassword)
                                               }}
                                               // onMouseDown={(e)=>{
                                               //     e.preventDefault();
                                               // }}
                                               edge="end"
                                           >
                                               {!showPassword ? <VisibilityOff /> : <Visibility />}
                                           </IconButton>
                                       </InputAdornment>
                                   ),
                               }}
                               onKeyDown={async (e) =>{
                                   if (e.key === 'Enter'){
                                       if (!username || !password){
                                           alert('กรุณาใส่บัญชีผู้ใช้และรหัสผ่านให้ครบ')
                                       } else{
                                           await submitLogin();
                                       }
                                   }
                               }}
                           />
                           <Button
                               type="submit"
                               fullWidth
                               variant="contained"
                               disabled={isLoading}
                               sx={{ mt: '60px', mb: 2, backgroundColor:'#458AF1' }}
                               onClick={ async (e)=>{
                                   e.preventDefault();
                                   if (!username || !password){
                                       alert('กรุณาใส่บัญชีผู้ใช้และรหัสผ่านให้ครบ')
                                   } else{
                                       await submitLogin();
                                   }
                               }}
                           >
                               เข้าสู่ระบบ
                           </Button>

                           {/*<Grid container alignItems="center" justifyContent="center" sx={{ pt:'20px' }}>*/}
                           {/*    /!*<Link href="#" variant="body2" style={{ textDecoration: 'none' }}>*!/*/}
                           {/*    /!*    ลืมรหัสผ่าน ? &nbsp;*!/*/}
                           {/*    /!*</Link>*!/*/}
                           {/*    <Typography sx={{color:'#4677A0'}}>ติดต่อเรา support_xxxxxxxxx@allnow.co.th</Typography>*/}
                           {/*    /!*<Link href="#" variant="body2" style={{ textDecoration: 'none' }}>*!/*/}
                           {/*    /!*    &nbsp;*!/*/}
                           {/*    /!*    ติดต่อเรา xxx@allnow.co.th*!/*/}
                           {/*    /!*</Link>*!/*/}
                           {/*</Grid>*/}
                       </Grid>
                   </Box>
               </Grid>
           </Grid>
        </>
    )
}