import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useForm , Controller } from "react-hook-form";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileDownload from "js-file-download";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {API} from "../../config/api";

function DowloadReportsDailyAnl() {
  const pathApi = new API().getCusAPI();
  const [loading, setLoading] = useState(false);

  // ------------ ค้นหาข้อมูลตามวันที่ ---------------

  const {
    register: register,
    handleSubmit: handleSubmitSearch,
    reset: resetInsert,
    control
  } = useForm();

  const SearchDateForDowload = (data) => {

    const startDate = new Date(data.posting_date_start);
    const endDate   = new Date(data.posting_date_end);

    // ตัวอย่างการกำหนดรูปแบบวันที่ในฟอร์แมตของ "DD/MM/YYYY"
    const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedStartDate = startDate.toLocaleDateString('en-GB', dateOptions);
    const formattedEndDate = endDate.toLocaleDateString('en-GB', dateOptions);

    console.log(data);
    setLoading(true);
    axios
      .post(pathApi + "dowloadReportsDailyAnlDemoTest4", data, {
        headers: {
          'Authorization':`Bearer `+localStorage.getItem("auth").replaceAll("\"",""),
          'Accept': 'application/json', 'Content-Type': 'multipart/form-data'
        }, responseType: 'arraybuffer'
      })
      .then(function (response) {
        // resetInsert();
        setLoading(false);

        FileDownload(response.data, `Makro ${formattedStartDate}-${formattedEndDate}.xlsx`);

      })
      .catch(function (error) {
        setLoading(false);
        Swal.fire({
          title: "เกิดข้อผิดพลาดในการ dowload ไฟล์",
          // text: result.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {});

        console.error(error);
      });
  };

  // ------------ End ค้นหาข้อมูลตามวันที่ ----------

  return (
    <>

      {loading ? (

          <LinearProgress />
      ) : ( <></> )}

      <form onSubmit={handleSubmitSearch(SearchDateForDowload)}>
        <Grid container spacing={2} sx={{ padding: "15px" }}>
          <Grid xs={12}>
            <Typography fontSize={"25px"}>ออกรายงาน Makro</Typography>
          </Grid>


          <Grid xs={3} >
            <Typography variant="subtitle1">
              Posting Date : จากวันที่
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <Controller
                  name="posting_date_start"
                  control={control}
                  defaultValue={null} // ค่าเริ่มต้น
                  render={({ field }) => (
                      <DatePicker
                          {...field}
                          views={['day']}
                          openTo="day"
                          format="DD/MM/YYYY"
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                      />
                  )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid xs={9} >
            <Typography variant="subtitle1">
              Posting Date : ถึงวันที่
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <Controller
                  name="posting_date_end"
                  control={control}
                  defaultValue={null} // ค่าเริ่มต้น
                  render={({ field }) => (
                      <DatePicker
                          {...field}
                          views={['day']}
                          openTo="day"
                          format="DD/MM/YYYY"
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                      />
                  )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid xs={3} >
            <Typography variant="subtitle1">
              Delivery Date : จากวันที่
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <Controller
                  name="delivery_date_start"
                  control={control}
                  defaultValue={null} // ค่าเริ่มต้น
                  render={({ field }) => (
                      <DatePicker
                          {...field}
                          views={['day']}
                          openTo="day"
                          format="DD/MM/YYYY"
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                      />
                  )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid xs={9} >
            <Typography variant="subtitle1">
              Delivery Date : ถึงวันที่
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <Controller
                  name="delivery_date_end"
                  control={control}
                  defaultValue={null} // ค่าเริ่มต้น
                  render={({ field }) => (
                      <DatePicker
                          {...field}
                          views={['day']}
                          openTo="day"
                          format="DD/MM/YYYY"
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField fullWidth {...params} variant="outlined" />}
                      />
                  )}
              />
            </LocalizationProvider>
          </Grid>


          {/*<Grid xs={6}>*/}
          {/*  <Typography>Posting Date : จากวันที่</Typography>*/}
          {/*  <TextField fullWidth id="outlined-basic" type="date"   variant="outlined"  {...register("posting_date_start")} />*/}
          {/*</Grid>*/}

          {/*<Grid xs={6}>*/}
          {/*  <Typography>Posting Date : ถึงวันที่</Typography>*/}
          {/*  <TextField fullWidth id="outlined-basic" type="date"  variant="outlined" {...register("posting_date_end")} />*/}
          {/*</Grid>*/}

          {/*<Grid xs={6}>*/}
          {/*  <Typography>Delivery Date : จากวันที่</Typography>*/}
          {/*  <TextField fullWidth id="outlined-basic" type="date"  variant="outlined"  {...register("delivery_date_start")}  />*/}
          {/*</Grid>*/}

          {/*<Grid xs={6}>*/}
          {/*  <Typography>Delivery Date : ถึงวันที่</Typography>*/}
          {/*  <TextField fullWidth id="outlined-basic" type="date"  variant="outlined" {...register("delivery_date_end")} />*/}
          {/*</Grid>*/}

          <Grid xs={12}>
            <Stack spacing={2} direction="row" sx={{ paddingTop: "15px" }}>
              <Button
                variant="contained"
                type="submit"
                size={"large"}
              >
                <TextSnippetIcon/>
                ออกรายงาน
              </Button>

              <Button
                  variant="outlined"
                  type="reset"
                  size={"large"}
              >
                รีเซต
              </Button>
            </Stack>
          </Grid>

        </Grid>
      </form>

    </>
  );
}

export default DowloadReportsDailyAnl;
