import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import {AllOverview} from "../sections/overview/all-overview";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

export default function ChartTest(props) {

    return (
        <div style={{width: '100%'}}>
            <Grid
                container
                spacing={2}
                sx={{
                    pt: 1, pl: {xs: 0, md: 1},
                    display: {xs: 'block', sm: 'block'},
                }}
            >
                <Grid container item sx={{mb: '5px'}}>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                        <Typography variant="subtitle1">
                            วันที่อัพโหลด (From):
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                    label={'DD/MM/YY'}
                                    slotProps={{
                                        textField: {size: 'small'},
                                        actionBar: {
                                            actions: ['clear'],
                                        },
                                    }}
                                    onChange={(newValue) => {

                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            size="small"
                                            fullWidth
                                            sx={{
                                                '& legend': {display: 'none'},
                                                '& fieldset': {top: 0},
                                            }}
                                        />
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                        <Typography variant="subtitle1">
                            วันที่อัพโหลด (To):
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                    label={'DD/MM/YY'}
                                    slotProps={{
                                        textField: {size: 'small'},
                                        actionBar: {
                                            actions: ['clear'],
                                        },
                                    }}
                                    onChange={(newValue) => {

                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            size="small"
                                            fullWidth
                                            sx={{
                                                '& legend': {display: 'none'},
                                                '& fieldset': {top: 0},
                                            }}
                                        />
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                        <Typography variant="subtitle1">
                            วันที่อัพโหลด (From):
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                    label={'DD/MM/YY'}
                                    slotProps={{
                                        textField: {size: 'small'},
                                        actionBar: {
                                            actions: ['clear'],
                                        },
                                    }}
                                    onChange={(newValue) => {

                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            size="small"
                                            fullWidth
                                            sx={{
                                                '& legend': {display: 'none'},
                                                '& fieldset': {top: 0},
                                            }}
                                        />
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{pt: 1, px: 1}}>
                        <Typography variant="subtitle1">
                            วันที่อัพโหลด (To):
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                    label={'DD/MM/YY'}
                                    slotProps={{
                                        textField: {size: 'small'},
                                        actionBar: {
                                            actions: ['clear'],
                                        },
                                    }}
                                    onChange={(newValue) => {

                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            size="small"
                                            fullWidth
                                            sx={{
                                                '& legend': {display: 'none'},
                                                '& fieldset': {top: 0},
                                            }}
                                        />
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}