import axios from "axios";
import {API} from "../../config/api";

export default class ApiAllocation {
    static async export(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'allocation_export',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'multipart/form-data',
                    },responseType: 'arraybuffer'
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async getBu(data) {
        try {
            return await axios.post(
                new API().getCusAPI()+'allocationReportGetBu',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    }
                }
            );
        } catch (e) {
            return e;
        }
    }

    static async allocationReport(data) {
        try {
            return await axios.post(
                new API().getAllocateAPI()+'export',
                data,
                {headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    },responseType: 'blob'
                }
            );
        } catch (e) {
            return e;
        }
    }
}