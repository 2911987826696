import React, {Component, useState, useContext, useEffect, useRef} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useTheme} from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";
import Home from './Home.js';
import ApiImportFileXlsx from '../services/api/ApiImportFileXlsx';
import ApiShipmentImportFile from '../services/api/ApiShipmentImportFile';
import {AuthContext} from '../App.js';
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RefreshIcon from '@mui/icons-material/Refresh';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Swal from "sweetalert2";
import ApiOrderManagement from "../services/api/ApiOrderManagement";
import Loading from "./Loading";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

const FileDownload = require('js-file-download');
const styles = {
    black: {},
    subIcon: {
        color: '#FEFEFE',
    },
    t: {
        textAlign: 'left',
        border: '1px solid #dddddd',
        padding: '10px',
        verticalAlign: 'top',
        lineBreak: 'auto',
    },
    tr: {
        backgroundColor: '#fff',
        padding: '5px',
    },
};
export default function ListOrderDetails() {
    const {
        open,
        setOpen,
        userNPass,
        setUserNPass,
        isLoading,
        setIsLoading,
        isProfiles,
        setIsProfiles,
        isProgress,
        setIsProgress
    } = useContext(AuthContext);

    const {orderId} = useParams();

    const [searchParams] = useSearchParams();
    const trickingNo = searchParams.get('trickingNo');
    const isWebView = searchParams.get('webView');

    const [drawerOpenBtn, setDrawerOpenBtn] = useState(false);
    const [details, setDetails] = useState({});
    const [item, setItem] = useState({});
    const [value, setValue] = useState(0);
    const [valueItem, setValueItem] = useState(0);
    const [stepper, setStepper] = useState(0);
    const [detailDelivered, setDetailDelivered] = useState({});


    useEffect(() => {
        async function inti() {
            setIsProgress(true)
            await fetchData().catch((e) => {
                console.log(e)
                setIsLoading(false)
                setIsProgress(false)
            })
        }

        inti().catch(console.error)
    }, []);

    const fetchData = async () => {
        setIsLoading(true)
        setIsProgress(true)
        let res = await ApiOrderManagement.getByTrickingNo({id: orderId});
        if (res.status === 200) {
            // console.log('res.data[0]');
            // console.log(res.data[0]);
            // console.log('res.data[0].details[0].orderItem');
            // console.log(res.data[0].details[0].orderItem);
            if (res.data[0].details[0].orderItem.length > 0) {
                // console.log(res.data[0].details[0].orderItem[0])
                // console.log(res.data[0].details[0].orderItem)
                setItem(res.data[0].details[0].orderItem)
            } else {
                console.log('not have: res.data[0].details[0].orderItem')
            }
            let testDelivered = res.data[0].details.map((item, index) => ({
                [index]: item.trackingTruckStatus === 'Delivered'
            }));
            const consolidatedResult = testDelivered.reduce((acc, obj) => ({...acc, ...obj}), {});
            setDetailDelivered(consolidatedResult);
            setDetails(res.data[0])
        }
        setIsLoading(false)
        setIsProgress(false)
    }

    const handleChange = (event, newValue) => {
        console.log('newValue')
        console.log(newValue)
        setValue(newValue);
        setItem(details.details[newValue].orderItem)
        setValueItem(0);
    };

    const handleChange2 = (newValue) => {
        console.log('newValue')
        console.log(newValue)
        setValue(newValue);
        setItem(details.details[newValue].orderItem)
        setValueItem(0);
    };

    const handleChangeItem = (event, newValue) => {
        setValueItem(newValue);
    };

    const resetHistory = async () => {
        setHistory(
            history => ({
                ...history,
                ...{
                    dialog: false,
                    dataList: [],
                }
            })
        )
    };

    const [history, setHistory] = useState({
        dialog: false,
        dataList: [],
    });

    return (
        <>
            {
                !setIsProgress
                    ? <Loading/>
                    :
                    <Box sx={{display: {xs: open ? 'none' : 'block', sm: 'block'}, overflow: 'auto'}}>
                        <Grid container sx={{pb: '15px'}}>
                            <Grid container item direction="row" alignItems="center">
                                <Grid container item direction="row" alignItems="center" xs={8} sm={8} md={8} lg={8}>
                                    {
                                        isWebView !== "true" ?
                                            <Tooltip title="Back to Order management page" TransitionComponent={Zoom}
                                                     placement="bottom" arrow>
                                                <IconButton onClick={() => {
                                                    window.location.pathname = `/shipment/orderManagement`
                                                }}>
                                                    <ArrowBackIcon/>
                                                </IconButton>
                                            </Tooltip> : undefined
                                    }

                                    <Typography variant="h5" sx={{pl: isWebView === "true" ? '16px' : '0px', fontSize: '22px'}}>
                                        รายละเอียดออเดอร์
                                        หมายเลขการติดตาม:&nbsp; {trickingNo && (trickingNo)} &nbsp;&nbsp;สถานะ: {details ? details.orderStatus : '-'}
                                    </Typography>
                                </Grid>
                                <Grid container item direction="column" alignItems="end" xs={4} sm={4} md={4} lg={4}>
                                    <Button variant="contained" disableElevation
                                            onClick={async () => {
                                                setIsLoading(true)

                                                const getHistory = await ApiOrderManagement.getOrderStatusHistory({trickingNo: trickingNo});
                                                if (getHistory.status === 200) {
                                                    setHistory(
                                                        history => ({
                                                            ...history,
                                                            ...{
                                                                dialog: true,
                                                                dataList: getHistory.data,
                                                            }
                                                        })
                                                    )
                                                }

                                                setIsLoading(false)
                                            }}
                                            sx={{
                                                backgroundColor: '#6F9EE8',
                                                color: '#ffffff',
                                                ':hover': {
                                                    backgroundColor: '#4380E1',
                                                    color: 'white',
                                                },
                                                m: '3px',
                                            }}
                                    >
                                        ประวัติ
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                        {/*<Divider sx={{mb: '10px'}}/>*/}
                        <Grid container spacing={2} sx={{pt: 1, pl: {xs: 0, md: 1}, pb: '40px'}}>
                            <Grid container item>
                                <Grid xs={12} item>
                                    {/*search   "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset" | "collapse" | "separate"    */}
                                    <table style={{
                                        width: '100%',
                                        border: '1px solid #dddddd',
                                        borderColor: '#dddddd',
                                        borderCollapse: 'collapse'
                                    }}>
                                        <tr style={styles.tr}>
                                            <th style={{
                                                textAlign: 'left',
                                                border: '1px solid #dddddd',
                                                padding: '10px',
                                                width: '20%'
                                            }}>หมายเลขการติดตาม
                                            </th>
                                            <td style={styles.t}>{trickingNo? trickingNo : "-"}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>เวลาอัพโหลด</th>
                                            <td style={styles.t}>{details ? details.uploadDate : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ชื่อไฟล์อัพโหลด</th>
                                            <td style={styles.t}>{details ? details.importFileResultFileName : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ชื่อคู่ค้า</th>
                                            <td style={styles.t}>{details ? details.buName : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ต้นทาง</th>
                                            <td style={styles.t}>{details ? details.originNameTh : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ปลายทาง</th>
                                            <td style={styles.t}>{details ? details.destinationNameTh : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ชื่อคู่ค้าที่วิ่งงาน</th>
                                            <td style={styles.t}>{details ? details.actualVendorName : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ชื่อคู่ค้าที่จัดสรร</th>
                                            <td style={styles.t}>{details ? details.planedVendorCodeName : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ทะเบียนที่วิ่งงาน</th>
                                            <td style={styles.t}>{details ? details.actualVehicleNumberPlate : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ทะเบียนจังหวัด</th>
                                            <td style={styles.t}>{details ? details.actualVehicleNumberPlateProvinces : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ชื่อคนขับที่วิ่งงาน</th>
                                            <td style={styles.t}>{details ? details.actualDriverName : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>เบอร์โทรศัพท์คนขับ</th>
                                            <td style={styles.t}>{details ? details.driverTel : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>วันเวลาจ่ายงาน</th>
                                            <td style={styles.t}>{details ? details.originExpectedPickupDate : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>Origin earliest received date</th>
                                            <td style={styles.t}>{details ? details.originEarliestReceivedDate : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>Pre Cool Date</th>
                                            <td style={styles.t}>{details.planPreCoolDate}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>วันเวลารถจะเข้ารับเอกสาร (Start Load)</th>
                                            <td style={styles.t}>{details ? details.originPlanPickupDate : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>Plan Finish Load Date</th>
                                            <td style={styles.t}>{details.planFinishLoad}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>Last Dispatch (Depart)</th>
                                            <td style={styles.t}>{details ? details.lastDispatchDate : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>วันเวลานัดส่งสาขา</th>
                                            <td style={styles.t}>{details ? details.destinationExpectedDeliveryDate : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ประเภท</th>
                                            <td style={styles.t}>{details ? details.orderType : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ทะเบียนหาง</th>
                                            <td style={styles.t}>{details ? details.trailerNo : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>หมายเลขโหลด</th>
                                            <td style={styles.t}>{details ? details.loadNo : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>น้ำหนัก</th>
                                            <td style={styles.t}>{details ? details.weight : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>volume</th>
                                            <td style={styles.t}>{details ? details.volume : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ประเภทรถ</th>
                                            <td style={styles.t}>{details ? details.buTruckType : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>Delivery Type</th>
                                            <td style={styles.t}>{details ? details.truckType : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>Distance</th>
                                            <td style={styles.t}>{details ? details.distance : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>สายใกล้/สายไกล</th>
                                            <td style={styles.t}>{details ? details.driverTotal : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ระยะเวลา</th>
                                            <td style={styles.t}>{details ? details.totalDuration : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>ระยะทาง</th>
                                            <td style={styles.t}>{details ? details.totalDistance : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>จำนวนจุดส่ง</th>
                                            <td style={styles.t}>{details ? details.drops : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>หมายเหตุ</th>
                                            <td style={styles.t}>{details ? details.remark : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>TMS Status Code</th>
                                            <td style={styles.t}>{details ? details.tmsStatusCode : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>TMS Order No</th>
                                            <td style={styles.t}>{details ? details.tmsOrderNo : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>Error Message</th>
                                            <td style={styles.t}>{
                                                (details.details?.filter((f) => (f.tmsStatusMessage?.length ?? 0) > 0).length ?? 0) > 0 ?
                                                    <div>
                                                        {
                                                            details.details.map((m, i) =>
                                                                <div style={{ padding: '5px 0px' }}>
                                                                    <span style={{ color: '#dc2f02', fontWeight: 500 }}>จุดส่ง {i + 1}</span>  {m.tmsStatusMessage ?? ''}
                                                                </div>
                                                            )
                                                        }
                                                    </div> : '-'
                                            }</td>
                                        </tr>
                                        <tr>
                                            <th style={styles.t}>สร้างโดย</th>
                                            <td style={styles.t}>{details ? details.createdBy : '-'}</td>
                                        </tr>
                                    </table>
                                </Grid>

                                {(() => {
                                    if (details.details && (details.details.length > 0)) {
                                        return (
                                            <Grid xs={12} item>
                                                {/*<Divider sx={{mt: '40px'}}/>*/}
                                                <Box sx={{width: '100%', mt: '70px'}}>

                                                    <Stepper activeStep={value} nonLinear alternativeLabel>
                                                        {details.details.map((step, index) => (
                                                            <Step
                                                                key={step.buTrackingNo}
                                                                completed={detailDelivered[index]}
                                                                style={{ position: 'relative' }}
                                                                // active={detailDelivered[index]}
                                                            >
                                                                <div style={{ paddingBottom: '5px', width: '100%', position: 'relative', textAlign: 'center' }}>
                                                                    <Typography
                                                                        align="center"
                                                                        position={'absolute'}
                                                                        onClick={() => {
                                                                            handleChange2(index)
                                                                        }}
                                                                        sx={{
                                                                            color: value === index ? '#1976D2' : '#000000DE',
                                                                            fontWeight: value === index ? 600 : 'light',
                                                                        }}
                                                                        style={{ width: '100%', textAlign: 'center', marginTop: '-25px', fontSize: '13px', cursor: 'pointer' }}
                                                                    >
                                                                        { step.planStoreArriveDate }
                                                                    </Typography>
                                                                </div>
                                                                <StepLabel
                                                                    onClick={() => {
                                                                        handleChange2(index)
                                                                    }}
                                                                    sx={{cursor: 'pointer', marginBottom: '16px'}}
                                                                    /*optional={
                                                                        value === index ? (
                                                                            <Typography variant="subtitle2" gutterBottom>รายการที่แสดง</Typography>
                                                                        ) : null
                                                                    }
                                                                    error={detailDelivered[index]}*/
                                                                >
                                                                    <Grid item container direction="column"
                                                                          justifyContent="center" alignItems="center">
                                                                        {(() => {
                                                                            if (step.destinationCode) {
                                                                                return (
                                                                                    <Typography
                                                                                        variant="caption"
                                                                                        sx={{
                                                                                            fontSize: '16px',
                                                                                            color: value === index ? '#1976D2' : '#000000DE',
                                                                                            fontWeight: value === index ? 600 : 'light',
                                                                                            // textDecoration: value === index ? 'underline' : false
                                                                                        }}
                                                                                    >
                                                                                        {step.destinationCode}
                                                                                    </Typography>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{
                                                                                fontSize: '14px',
                                                                                color: value === index ? '#1976D2' : '#000000DE',
                                                                                fontWeight: value === index ? 600 : 'light',
                                                                                // textDecoration: value === index ? 'underline' : false
                                                                            }}
                                                                        >
                                                                            {step.destinationName}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {/*test*/}
                                                                </StepLabel>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </Box>
                                                <Divider sx={{mt: '10px'}}/>
                                                <Box sx={{width: '100%'}}>
                                                    {/*<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                            {details.details.map((options, index) => {
                                                                console.log('index')
                                                                console.log(index)
                                                                return <Tab key={index} value={index} label={`${index + 1}`} />;
                                                            })}

                                                        </Tabs>
                                                    </Box>*/}
                                                    <table style={{
                                                        width: '100%',
                                                        border: '1px solid #dddddd',
                                                        borderColor: '#dddddd',
                                                        borderCollapse: 'collapse'
                                                    }}>
                                                        <tr style={styles.tr}>
                                                            <th style={{
                                                                textAlign: 'left',
                                                                border: '1px solid #dddddd',
                                                                padding: '10px',
                                                                width: '20%'
                                                            }}>หมายเลขการติดตาม
                                                            </th>
                                                            <td style={styles.t}>{details.details[value].buTrackingNo}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>ต้นทาง</th>
                                                            <td style={styles.t}>{details.details[value].originName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>ปลายทาง</th>
                                                            <td style={styles.t}>{details.details[value].destinationName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Order Type</th>
                                                            <td style={styles.t}>{details.details[value].orderType}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Total Volumn</th>
                                                            <td style={styles.t}>{details.details[value].totalVolumn}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Percent Vehicle Loaded</th>
                                                            <td style={styles.t}>{details.details[value].percentVehicleLoaded}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Kg Vehicle Loaded</th>
                                                            <td style={styles.t}>{details.details[value].kgVehicleLoaded}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Total Weight</th>
                                                            <td style={styles.t}>{details.details[value].totalWeight}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Total Distance</th>
                                                            <td style={styles.t}>{details.details[value].totalDistance}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Waiting Time</th>
                                                            <td style={styles.t}>{details.details[value].waitingTime}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Order Status</th>
                                                            <td style={styles.t}>{details.details[value].orderStatus}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>TMS Status Message</th>
                                                            <td style={styles.t}>{details.details[value].tmsStatusMessage}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>TMS OrderNo</th>
                                                            <td style={styles.t}>{details.details[value].tmsOrderNo}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Origin Earliest Received Date</th>
                                                            <td style={styles.t}>{details.details[value].originEarliestReceivedDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>วันเวลารถจะเข้ารับเอกสาร (Store Arrived)</th>
                                                            <td style={styles.t}>{details.details[value].originPlanPickupDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>วันเวลาจ่ายงาน</th>
                                                            <td style={styles.t}>{details.details[value].originExpectedPickupDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>TS From Date</th>
                                                            <td style={styles.t}>{details.details[value].planTsFromDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>TS Till Date</th>
                                                            <td style={styles.t}>{details.details[value].destinationExpectedDeliveryDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Origin Expected DepartDate</th>
                                                            <td style={styles.t}>{details.details[value].originExpectedDepartDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Origin Expected Arrival Date (DC Arrive)</th>
                                                            <td style={styles.t}>{details.details[value].originExpectedArrivalDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Destination Earliest Arrival Date</th>
                                                            <td style={styles.t}>{details.details[value].destinationEarliestArrivalDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Truck Type</th>
                                                            <td style={styles.t}>{details.details[value].truckType}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={styles.t}>Delivery Trpe</th>
                                                            <td style={styles.t}>{details.details[value].deliveryTrpe}</td>
                                                        </tr>
                                                    </table>
                                                </Box>

                                                {
                                                    item && (item.length > 0)
                                                        ?
                                                        <Grid xs={12} item>
                                                            <Divider sx={{mt: '40px'}}/>
                                                            <Box sx={{width: '100%'}}>
                                                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                                                    <Tabs value={valueItem} onChange={handleChangeItem}
                                                                          aria-label="basic tabs example">
                                                                        {item.map((options, index) => {
                                                                            console.log('index item')
                                                                            console.log(index)
                                                                            return <Tab key={index} value={index}
                                                                                        label={`${index + 1}`}/>;
                                                                        })}
                                                                    </Tabs>
                                                                    <table style={{
                                                                        width: '100%',
                                                                        border: '1px solid #dddddd',
                                                                        borderColor: '#dddddd',
                                                                        borderCollapse: 'collapse'
                                                                    }}>
                                                                        <tr style={styles.tr}>
                                                                            <th style={{
                                                                                textAlign: 'left',
                                                                                border: '1px solid #dddddd',
                                                                                padding: '10px',
                                                                                width: '20%'
                                                                            }}>รายการสินค้า
                                                                            </th>
                                                                            <td style={styles.t}>{item[valueItem].itemDescription}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={styles.t}>ความกว้างของแพ็คเก็ต(เมตร)</th>
                                                                            <td style={styles.t}>{item[valueItem].packageWidgth}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={styles.t}>ความสูงของแพ็คเก็ต(เมตร)</th>
                                                                            <td style={styles.t}>{item[valueItem].packageHeight}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={styles.t}>ความยาวของแพ็คเก็ต(เมตร)</th>
                                                                            <td style={styles.t}>{item[valueItem].packageLenght}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={styles.t}>น้ำหนัก(กิโลกรัม)</th>
                                                                            <td style={styles.t}>{item[valueItem].weightPerPackage}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={styles.t}>จำนวน</th>
                                                                            <td style={styles.t}>{item[valueItem].numberOfPackages}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={styles.t}>จำนวนหน่วยต่อแพ็คเก็ต</th>
                                                                            <td style={styles.t}>{item[valueItem].numberOfItemPerPackage}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th style={styles.t}>หน่วย</th>
                                                                            <td style={styles.t}>{item[valueItem].packageUnitName}</td>
                                                                        </tr>
                                                                    </table>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        : <></>
                                                }
                                            </Grid>
                                        )
                                    }

                                })()}
                            </Grid>
                            {/*<Divider />*/}
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    disableElevation*/}
                            {/*    onClick={()=>{*/}
                            {/*        console.log(search)*/}
                            {/*        // setIsLoading(true)*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    test*/}
                            {/*</Button>*/}
                        </Grid>
                        <Dialog
                            fullWidth
                            open={history.dialog}
                            onClose={() => {
                                resetHistory();
                            }}
                            maxWidth="lg"
                        >
                            <DialogTitle id="alert-dialog-title">ประวัติ</DialogTitle>
                            <Grid item container direction="column" alignItems="center" justifyContent="center"
                                  sx={{pt: '8px', pb: '70px', px: '20px'}}>
                                <Table sx={{padding: 0}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{border: 1}} align={"center"}>เวลา</TableCell>
                                            <TableCell sx={{border: 1}} align={"center"}>จากสถานะ</TableCell>
                                            <TableCell sx={{border: 1}} align={"center"}>ไปสถานะ</TableCell>
                                            <TableCell sx={{border: 1}} align={"center"}>โดย</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (history.dataList?.length > 0
                                                    ? history.dataList
                                                    : [
                                                        {
                                                            id: '1',
                                                            updateDate: '-',
                                                            fromStatus: '-',
                                                            toStatus: '-',
                                                            updateBy: '-'
                                                        }
                                                    ]
                                            ).map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell align="center" sx={{
                                                        borderLeft: 1,
                                                        borderRight: 1
                                                    }}>{row.updateDate}</TableCell>
                                                    <TableCell sx={{borderRight: 1}}>{row.fromStatus}</TableCell>
                                                    <TableCell sx={{borderRight: 1}}>{row.toStatus}</TableCell>
                                                    <TableCell sx={{borderRight: 1}}>{row.updateBy}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>

                        </Dialog>
                    </Box>
            }
        </>
    )
}