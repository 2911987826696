import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useForm } from "react-hook-form";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {API} from "../../config/api";

function CustomerMaster() {
  const pathApi = new API().getCusAPI();

  const [loading, setLoading] = useState(false);

  // ------------ modal เพิ่มข้อมูล ------------
  const [openInsert, setOpenInsert] = React.useState(false);
  const handleOpenInsert = () => setOpenInsert(true);
  const handleCloseInsert = () => setOpenInsert(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // ------------ end modal เพิ่มข้อมูล ---------

  // ------------ ดึงข้อมูลมาแสดง --------------
  const [customerMaster, setCustomerMaster] = useState([]);

  const { register: register, handleSubmit: handleSubmitSearch} = useForm();

  const getCustomerMaster = (data) => {
    const formData = new FormData();
    formData.append("email", data["email"]);
    formData.append("nameCustomer", data["name_customer"]);
    formData.append("serviceType", data["service_type"]);
    formData.append("autoAllocateTruck", data["auto_allocate_truck"]);
    formData.append("isIntegrateWithTms", data["is_integrate_with_tms"]);

    setLoading(true);
    axios
      .post(pathApi + "masters/customerGet", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setCustomerMaster(response.data.data);
        setLoading(false);
        console.log(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    const emptyData = {
      email: "",
      name_customer: "",
      service_type: "",
    };
    setLoading(true);
    getCustomerMaster(emptyData);
  }, []);

  // ------------ End ดึงข้อมูลมาแสดง ------

  // ------------ เพิ่มข้อมูล / update ---------------

  const [dataCustomerEdit, setDataCustomerEdit] = useState(null);

  const {
    register: registerInsert,
    handleSubmit: handleSubmitInsert,
    reset: resetInsert,
  } = useForm();
  const {
    register: registerEdit,
    handleSubmit: handleSubmitUpdate,
    reset: resetEdit,
  } = useForm();

  const customerEdit = (item) => {
    setDataCustomerEdit(item);
    handleOpenEdit();
    resetEdit();
  };

  const InsertCustomerMaster = (data) => {
    const formData = new FormData();
    formData.append("idCustomer", data["id_customer"]);
    formData.append("email", data["email"]);
    formData.append("nameCustomer", data["name_customer"]);
    formData.append("code", data["code"]);
    formData.append("serviceType", data["service_type"]);
    formData.append("autoAllocateTruck", data["auto_allocate_truck"]);
    formData.append("isIntegrateWithTms", data["is_integrate_with_tms"]);
    formData.append("needPickupPlanFromSub", data["need_pickup_plan_from_sub"]);

    setLoading(true);
    axios
      .post(pathApi + "masters/customerInsert", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        handleCloseInsert();
        handleCloseEdit();
        Swal.fire({
          title: "ดำเนินการสำเร็จ",
          // text: result.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          const emptyData = {
            email: "",
            name_customer: "",
            service_type: "",
          };
          getCustomerMaster(emptyData);
        });
        resetInsert();
        resetEdit();
        setLoading(false);

        console.log(response.data.data);
      })
      .catch(function (error) {
        handleCloseInsert();
        console.error(error);
      });
  };

 // ------------ End เพิ่มข้อมูล / update ----------

 // ------------ ลบ customer --------------------

 const handleDeleteClick = (item) => {
    if (window.confirm("คุณต้องการลบรายการนี้หรือไม่?")) {
      delCustomerMaster(item);
    }
  };

  const delCustomerMaster = (idDel) => {
    
    setLoading(true);
    axios
      .post(pathApi + `masters/customerDel/${idDel}` , {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setLoading(false);

        Swal.fire({
            title: "ลบข้อมูลสำเร็จ",
            // text: result.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            const emptyData = {
              email: "",
              name_customer: "",
              service_type: "",
            };
            getCustomerMaster(emptyData);
          });

      })
      .catch(function (error) {

        Swal.fire({
            title: "ลบข้อมูลไม่สำเร็จ",
            // text: result.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            const emptyData = {
              email: "",
              name_customer: "",
              service_type: "",
            };
            getCustomerMaster(emptyData);
          });

        console.error(error);
      });
  };
 // ------------ End ลบ customer ---------------

// ------------ Table --------------------------
  const columns = [
    { field: "no", headerName: "No", flex: 1 },
    { field: "code", headerName: "code", flex: 1 },
    { field: "nameCustomer", headerName: "ชื่อ", flex: 1 },
    { field: "email", headerName: "email", flex: 1 },
    { field: "serviceType", headerName: "ประเภท", flex: 1 },
    {
      field: "id",
      headerName: " ",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
console.log(params.row);
        return (
          <>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                size="small"
                onClick={customerEdit.bind(null, params.row)}
              >
                แก้ไข
              </Button>

              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDeleteClick.bind(null,params.row.id)}
              >
                ลบ
              </Button>
            </Stack>
          </>
        );
      },
    },
  ];

  const rows = customerMaster.map((item, index) => ({
    id: item.id,
    no: index + 1,
    nameCustomer: item.nameCustomer,
    email: item.email,
    code: item.code,
    serviceType: item.serviceType,
    autoAllocateTruck: item.autoAllocateTruck,
    isIntegrateWithTms: item.isIntegrateWithTms,
    needPickupPlanFromSub: item.needPickupPlanFromSub,
  }));

  // ------------ End Table ------------------

  const serviceType = ["Cross Dock_Chill", "Super Sub"];
  const yesOrno = ["ใช่"];
  return (
    <>
      <form onSubmit={handleSubmitSearch(getCustomerMaster)}>
        <Grid container spacing={2} sx={{ padding: "15px" }}>
          <Grid xs={12}>
            <Typography>จัดการข้อมูล</Typography>
          </Grid>

          <Grid xs={3}>
            <TextField
              fullWidth
              size={"small"}
              label="email"
              variant="outlined"
              {...register("email")}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              fullWidth
              size={"small"}
              label="ชื่อลูกค้า"
              variant="outlined"
              {...register("name_customer")}
            />
          </Grid>
          <Grid xs={3}>
            <Autocomplete
              fullWidth
              size={"small"}
              disablePortal
              id="combo-box-demo"
              options={serviceType}
              renderInput={(params) => (
                <TextField
                  {...register("service_type")}
                  {...params}
                  label="ประเภทงาน"
                />
              )}
            />
          </Grid>

          <Grid xs={3}>
            <Button variant="contained" type="submit">
              ค้นหา
            </Button>
          </Grid>

          <Grid xs={3}>
            <Autocomplete
                fullWidth
                size={"small"}
                disablePortal
                id="combo-box-demo"
                options={yesOrno}
                renderInput={(params) => (
                    <TextField
                        {...register("auto_allocate_truck")}
                        {...params}
                        label="จัดสรรอัตโนมัติ"
                    />
                )}
            />
          </Grid>

          <Grid xs={3}>
            <Autocomplete
                fullWidth
                size={"small"}
                disablePortal
                id="combo-box-demo"
                options={yesOrno}
                renderInput={(params) => (
                    <TextField
                        {...register("is_integrate_with_tms")}
                        {...params}
                        label="เชื่อมต่อกับ TMS"
                    />
                )}
            />
          </Grid>

        </Grid>
      </form>

      <Grid container spacing={2} sx={{ paddingX: "15px" }}>
        <Grid xs={3}>
          <Button variant="contained" onClick={handleOpenInsert}>
            เพิ่มข้อมูล
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ paddingX: "20px", paddingTop: "20px" }}>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            loading={loading}
            autoWidth
            rows={rows}
            columns={columns}
            disableColumnMenu
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[50, 100]}
            components={{
              LoadingOverlay: () => <LinearProgress />,
            }}
          />
        </div>
      </Grid>

      <Modal
        open={openInsert}
        onClose={handleCloseInsert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            + เพิ่มข้อมูล
          </Typography>

          <form onSubmit={handleSubmitInsert(InsertCustomerMaster)}>
            <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="ชื่อ"
                  variant="outlined"
                  {...registerInsert("name_customer")}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="email"
                  variant="outlined"
                  multiline
                  rows={4}
                  {...registerInsert("email")}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="code"
                  variant="outlined"
                  {...registerInsert("code")}
                  required
                />
              </Grid>
              <Grid xs={12}>
                <Autocomplete
                  fullWidth
                  size={"small"}
                  disablePortal
                  defaultValue={""}
                  id="combo-box-demo"
                  options={serviceType}
                  renderInput={(params) => (
                    <TextField
                      {...registerInsert("service_type")}
                      {...params}
                      label="ประเภทงาน"
                      required
                      
                    />
                  )}
                />
              </Grid>

              <Grid xs={12}>
                <Stack spacing={2} direction="row">

                  <FormGroup>
                    <FormControlLabel  control={<Checkbox />} value={1} label="จัดสรรอัตโนมัติ" {...registerInsert("auto_allocate_truck")}/>
                    <FormControlLabel  control={<Checkbox />} value={1} label="เชื่อมต่อกับ TMS" {...registerInsert("is_integrate_with_tms")} />
                    <FormControlLabel  control={<Checkbox />} value={1} label="ต้องการเวลาเข้ารับเอกสารจากซัพหรือปล่าว" {...registerInsert("need_pickup_plan_from_sub")} />
                  </FormGroup>

                </Stack>
              </Grid>

              <Grid xs={12}>
                <Stack spacing={2} direction="row">
                  <Button variant="contained" type="submit">
                    เพิ่มข้อมูล
                  </Button>
                  <Button variant="outlined" type="reset">
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            แก้ไขข้อมูล
          </Typography>

          <form onSubmit={handleSubmitUpdate(InsertCustomerMaster)}>
            <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="ชื่อ"
                  variant="outlined"
                  defaultValue={
                    dataCustomerEdit && dataCustomerEdit.nameCustomer
                  }
                  {...registerEdit("name_customer")}
                />

                <input
                  fullWidth
                  size="small"
                  label="id"
                  variant="outlined"
                  defaultValue={dataCustomerEdit && dataCustomerEdit.id}
                  {...registerEdit("id_customer")}
                  type="hidden"
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="email"
                  variant="outlined"
                  multiline
                  rows={4}
                  defaultValue={dataCustomerEdit && dataCustomerEdit.email}
                  {...registerEdit("email")}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="code"
                  variant="outlined"
                  defaultValue={dataCustomerEdit && dataCustomerEdit.code}
                  {...registerEdit("code")}
                />
              </Grid>
              <Grid xs={12}>
                <Autocomplete
                  fullWidth
                  size={"small"}
                  disablePortal
                  id="combo-box-demo"
                  options={serviceType}
                  value={dataCustomerEdit && dataCustomerEdit.serviceType}
                  renderInput={(params) => (
                    <TextField
                      {...registerEdit("service_type")}
                      {...params}
                      label="ประเภทงาน"
                    />
                  )}
                />
              </Grid>

              <Grid xs={12}>
                <Stack spacing={2} direction="row">

                  <FormGroup>
                    <FormControlLabel
                        control={
                          <Checkbox
                              defaultChecked={dataCustomerEdit && dataCustomerEdit.autoAllocateTruck === true}
                          />
                        }
                        value={1}
                        label="จัดสรรอัตโนมัติ"
                        {...registerEdit("auto_allocate_truck")}
                    />
                    <FormControlLabel
                        control={
                          <Checkbox
                              defaultChecked={dataCustomerEdit && dataCustomerEdit.isIntegrateWithTms === true}
                          />
                        }
                        value={1}
                        label="เชื่อมต่อกับ TMS"
                        {...registerEdit("is_integrate_with_tms")}
                    />

                    <FormControlLabel
                        control={
                          <Checkbox
                              defaultChecked={dataCustomerEdit && dataCustomerEdit.needPickupPlanFromSub === true}
                          />
                        }
                        value={1}
                        label="ต้องการเวลาเข้ารับเอกสารจากซัพหรือปล่าว"
                        {...registerEdit("need_pickup_plan_from_sub")}
                    />

                  </FormGroup>
                </Stack>
              </Grid>

              <Grid xs={12}>
                <Stack spacing={2} direction="row">
                  <Button variant="contained" type="submit">
                    Update ข้อมูล
                  </Button>
                  <Button variant="outlined" type="reset">
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default CustomerMaster;
