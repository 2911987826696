import React from 'react';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo1.jpg';
import Grid from "@mui/material/Grid";

export default function Loading(props) {

    return(
        <Grid container alignItems="center" justifyContent="center" sx={{ pt:'100px' }} direction={"column"}>
            <img src={logo} width={'250px'} className="image" />
            <Typography
                sx={{
                    fontSize: '22px',
                    // fontWeight: 'bold',
                    pt:'30px',
                    pb:'100px',
                }}
            >
                {
                    props.isWebView === "true" ? "Load Data..." : "ยินดีต้อนรับเข้าสู่ระบบ Customer portal"
                }
            </Typography>
        </Grid>
    )
}